<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
          @click="$router.back()"
          href="#"
          class="
            text-dark-75
            font-weight-bolder
            text-hover-primary
            mb-1
            font-size-lg
            d-flex
            flex-row
            align-items-center
          "
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Nilai Ujian Siswa
              </span>
              <span
                class="text-muted mt-3 font-weight-bold font-size-sm"
                v-if="!isLoading && currentUjian.name"
                >{{ currentUjian.name }} - {{ currentUjian.name }}</span
              >
            </h3>
            <div class="card-toolbar">
              <a
                @click="modalImport = true"
                class="btn btn-primary font-weight-bolder font-size-sm mr-1"
              >
                Export Nilai Akhir</a
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <ul
              class="nav nav-dark nav-bold nav-tabs nav-tabs-line mb-4"
              role="tablist"
              ref="builder-tab"
            >
              <li
                class="nav-item"
                v-for="(kelas, i) in currentUjian.class_id"
                :key="`${kelas.class_name}${i}`"
              >
                <a
                  :class="`nav-link ${i == 0 ? 'active' : ''}`"
                  v-on:click="(event) => setActiveTab(event)"
                  :data-tab="i"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  {{ kelas.class_name }}
                </a>
              </li>
            </ul>
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :items="items"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada histori ujian ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada histori ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #cell(siswa)="data">
                <b-row class="align-items-center">
                  <b-col cols="auto">
                    <span class="symbol symbol-50 symbol-light mr-2 mt-2">
                       <img class="symbol-label" src="/media/users/default.jpg" alt="" />
                    </span>
                  </b-col>
                  <b-col>
                    <a
                      class="
                        text-dark
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >{{ data.item.student_id.no_induk }}</a
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      data.item.student_id.full_name
                    }}</span>
                  </b-col>
                </b-row>
              </template>
              <template #cell(nilai)="data">
                <b-badge class="mr-1" pill href="#" variant="info">
                  <!-- {{ data.item.time }} Menit -->
                </b-badge>
                <span
                  v-if="data.item.scores != null"
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ Math.round(data.item.scores) }} pts
                </span>
                <div v-else>-</div>
              </template>
              <template #cell(waktu)="data">
                <b-badge class="mr-1" pill href="#" variant="info">
                  <!-- {{ data.item.waktu }} Menit -->
                  {{ data.item.remaining_time }} Menit
                </b-badge>
                <!-- v-if="data.item.siswa.one_nilai_ujian.elapsed_time != null" -->
                <!-- <span
                  v-if="false"
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.siswa.one_nilai_ujian.elapsed_time }} Menit
                </span>
                <div v-else>-</div> -->
              </template>
              <template #cell(tanggal)="data">
                <!-- v-if="data.item.siswa.one_nilai_ujian.created_at != null" -->

                <span
                  v-if="false"
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-primary
                    mr-1
                  "
                >
                  {{
                    data.item.siswa.one_nilai_ujian.created_at
                      | moment("dddd, LL HH:mm")
                  }}
                </span>
                <div v-else>-</div>
              </template>
              <template #cell(tab_change)="data">
                <!-- <span
                  v-if="data.item.siswa.one_nilai_ujian.tab_change != null"
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-primary
                    mr-1
                  "
                > -->
                <!-- {{ data.item.siswa.one_nilai_ujian.tab_change }} kali -->
                <!-- </span> -->
                <!-- <div v-else>-</div> -->
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <!--end::Navigation-->
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalImport"
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Export"
      cancel-title="Batalkan"
      centered
      @ok="exportNilai"
      title="Export nilai siswa"
    >
      <b-row>
        <b-col
          cols="4"
          v-for="(kelas, i) in currentUjian.class_id"
          :key="`${kelas.class_name}${i}`"
        >
          <b-button
            @click="selectedExport = `${kelas._id}`"
            :class="`btn ${
              selectedExport == kelas._id ? 'btn-success' : 'btn-primary'
            }  font-weight-bolder font-size-sm mr-1 w-100`"
          >
            {{ kelas.class_name }}
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
            @click="selectedExport = `all`"
            :class="`btn ${
              selectedExport == `all` ? 'btn-success' : 'btn-primary'
            }  font-weight-bolder font-size-sm mr-1 w-100`"
          >
            Semua Kelas
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_NILAI_SISWA } from "@/core/services/store/rekapnilai.module";
import mixin from "@/core/services/Helpers";

import {
  GET_LIST_RESULT_EXAM,
  GET_ONE_UJIAN,
  GET_FILE_RESULT_EXAM,
  URL_EXPORT
} from "@/core/services/store/ujian.module";

export default {
  name: "NilaiSiswaList",
  mixins: [mixin],
  data() {
    return {
      modalImport: false,
      items: [],
      selectedExport: "all",
      isLoading: false,
      tabIndex: 0,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "siswa",
          label: "Siswa",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu Pengerjaan",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal Pengerjaan",
          sortable: true,
        },
        {
          key: "nilai",
          label: "Nilai",
          sortable: true,
        },
        {
          key: "tab_change",
          label: "Jumlah Pindah Tab",
          sortable: true,
        },
      ],
      selectedMapel: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Nilai Ujian Siswa" }]);
    this.getData();

    console.log(this.currentUser);
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentRekapNilai", "currentUjianResult", "currentUjian","CurrentUser"]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.currentUser.user.level == 1;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },
  watch: {
    tabIndex() {
      const currentClassId = this.currentUjian.class_id[this.tabIndex]._id;
      this.items = this.currentUjianResult.filter((item) =>
        item.student_id.class_id.includes(currentClassId)
      );
      this.totalRows = this.items.length;
    },
  },
  methods: {
    chooseExport(item) {
      this.selectedExport = item;
    },
    exportNilai() {
      const params = new URLSearchParams();

      var fileName = `${this.currentUjian.name}.xlsx`;

      if (this.selectedExport != "all") {
        params.append("class_id", this.selectedExport);
        fileName = `${this.currentUjian.name}-${
          this.currentUjian.class_id.filter(
            (item) => (item._id = this.selectedExport)
          )[0].class_name
        }.xlsx`;
      }

      var token = this.currentUser.token;

      if(typeof token === "undefined") {
        token = this.currentUser.access_token;
      }

      params.append("exam_id", this.idUjian);
      params.append("token",token);

      window.location.href = `${URL_EXPORT}${params.toString()}`;

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then(() => {
          this.isLoading = false;

          this.getDataNilai();
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getDataNilai() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_LIST_RESULT_EXAM, this.idUjian)
        .then(() => {
          this.isLoading = false;
          console.log(this.currentUjianResult.length);
          this.items = this.currentUjianResult;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  },
};
</script>

<style scoped>
</style>
